
import { useStore } from "vuex";
import { useI18n } from "vue-i18n/index";
import { defineComponent, onMounted, ref } from "vue";
import ContractEdit from "@/views/contracts/ContractEdit.vue";
import ContractAspect from "@/views/contracts/ContractAspect.vue";
import VehicleMissions from "@/views/services/fleet/VehicleMissions.vue";
import ContractPriceScheduleRent from "@/views/contracts/ContractPriceScheduleRent.vue";
import ContractPriceScheduleMaintenance from "@/views/contracts/ContractPriceScheduleMaintenance.vue";


interface IPagination {
    page  : number;
    search: boolean;
    exists: number;
    total : number;
    offset: number;
}

export default defineComponent({
    name: "services-vehicle-insurances",
    components: {

        ContractAspect,
        ContractEdit,
        VehicleMissions,
        ContractPriceScheduleRent,
        ContractPriceScheduleMaintenance,
    },
    setup() {
        const { t, te }    = useI18n();
        let bool1        = ref(true);
        let bool2        = ref(true);

        const translate = (text, args = null) => {
            if (te(text)) {
                return t(text, args);
            } else {
                return text;
            }
        };


        const reset = (btn) => {

            (btn == 'loc') ? bool2.value = true  : bool1.value = true;


            // if (btn == 'loc') {
            //     // if (bool2.value = false) {
            //         bool2.value = true;
            //     // }
            // }

            // if (btn == 'maint') {
            //     // if (bool1.value = false) {
            //         bool1.value = true;
            //     // }
            // }

        };

        onMounted(() => {
        });

        return {
            translate,
            bool1,
            bool2,
            reset,
        };
    },
});
