
import { useI18n } from "vue-i18n/index";
import { useStore } from "vuex";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import axios, { AxiosResponse } from "axios";
import { defineComponent, onMounted, ref } from "vue";
import { Collapse, Tab } from "bootstrap";
import ApiRoutes from "@/core/config/ApiRoutes";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Contract from "@/views/contracts/Contract.vue";
// import EditVehicleModal from "@/components/modals/services/fleet/vehicles/EditVehicleModal.vue";
import SearchVehicleModal from "@/components/modals/services/fleet/vehicles/SearchVehicleModal.vue";

interface IPagination {
    page  : number;
    search: boolean;
    exists: number;
    total : number;
    offset: number;
}

export default defineComponent({
    name: "services-fleet-vehicles",
    props: {
        widgetClasses: String,
    },
    components: {
        Contract,
        SearchVehicleModal,
    },
    setup() {
        const { t, te } = useI18n();
        const store = useStore();

        let loading = ref(false);
        let activeContract = ref(null);
        let collapse = null as Collapse;
        const contracts = ref([]);
        const queryParamsDefault = {
            id                   :null,
            code                 :"",
            designation          :"",
            object               :"",
            path                 :"",
            date_begin           :"",
            date_end             :"",
            supplier_type_id     :0,
            supplier_id          :0,
            date_sign            :"",
            date_eff             :"",
            client_dom           :"",
            client_rep           :"",
            supplier_dom         :"",
            supplier_rep         :"",
            selected_file        :File,
            note                 :"",
        };
        const queryParams = ref({});
        Object.assign(queryParams.value, queryParamsDefault);

        const translate = (text, args = null) => {
            if (te(text)) {
                return t(text, args);
            } else {
                return text;
            }
        };

        const queryParamsLabels = {

            code                 :translate("contractCodeLabel"),
            designation          :translate("contractDesignationLabel"),
            object               :translate("contractObjectLabel"),
            date_begin           :translate("contractDateBeginLabel"),
            date_end             :translate("contractDateEndLabel"),
        };
        const pagination = ref<IPagination>({
            page  : 1,
            search: false,
            exists: 0,
            total : 0,
            offset: 10,
        });

        store.watch(
            (state, getters) => getters.getRefresh,
            (doRefresh) => {

                if (doRefresh > 0) {

                    if (doRefresh == 1) {
                        collapse.hide();
                    }

                    refresh();
                }
            });

        const goPage = (page: number) => {
            collapse.hide();

            pagination.value.page = page;

            getContracts();
        };

        const setOffset = (event) => {
            collapse.hide();

            pagination.value.offset = parseInt(event.target.value);

            getContracts();
        };

        const refresh = () => {
            pagination.value.page = 1;
            pagination.value.total = 0;
            pagination.value.offset = 10;

            getContracts();

            store.dispatch(Actions.REFRESH_VEHICLES, 0);
        };

        const scrollTo = (element: string) => {

            document.getElementById(element)?.scrollIntoView({
                behavior: 'smooth'
            });
        };

        const create = () => {
            store.dispatch(Actions.SET_VEHICLE, null);

            collapse.show();

            (new Tab(document.querySelector('#kt_tab_button_contract_1'))).show();
        };

        const edit = (contract) => {
            store.dispatch(Actions.SET_VEHICLE, contract);

            collapse.show();

            (new Tab(document.querySelector('#kt_tab_button_contract_1'))).show();
        };

        const confirmRemove = (contract_id: number) => {
            collapse.hide();

            Swal.fire({
                title: translate("warningHeading"),
                text: translate("contractConfirmDelete"),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: translate("confirm"),
                cancelButtonText: translate("cancel"),
                customClass: {
                    confirmButton: "btn btn-danger",
                    cancelButton: "btn btn-secondary",
                },
            }).then((result) => {

                if (result.isConfirmed) {
                    remove(contract_id);
                }
            })
        }

        const remove = (contract_id: number) => {
            collapse.hide();

            axios.delete(`${ApiRoutes.contracts.delete}/${contract_id}`)
                .then((response: AxiosResponse) => {

                    if (response.data.success) {

                        Swal.fire({
                            text: response.data.message,
                            icon: "success",
                            buttonsStyling: false,
                            confirmButtonText: translate("confirm"),
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                        }).then(() => {
                            refresh();
                        });
                    } else {

                        Swal.fire({
                            text: response.data.message,
                            icon: "error",
                            buttonsStyling: false,
                            confirmButtonText: translate("confirm"),
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                        });
                    }
                })
                .catch((error) => {

                    Swal.fire({
                        html: '<h3>' + translate("errorHeading") + '</h3><p>' + translate("errorConnectionMessage") + '</p>',
                        icon: "error",
                        buttonsStyling: false,
                        confirmButtonText: translate("confirm"),
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                    });
                });
        };

        const removeQueryParam = (name: string): void => {
            collapse.hide();

            if (queryParams.value && typeof queryParams.value[name] !== 'undefined') {
                queryParams.value[name] = queryParamsDefault[name];

                refresh();
            }
        };

        const getContracts = async () => {
            loading.value = true;

            activeContract.value = null;

            const params = {};
            Object.assign(params, queryParams.value, pagination.value);

            await axios.get(ApiRoutes.contracts.index, { params: params })
                .then((response: AxiosResponse) => {

                    loading.value = false;

                    pagination.value.search = response.data.search;
                    pagination.value.exists = response.data.exists;
                    pagination.value.total = response.data.total;

                    contracts.value = response.data.rows;
                }).catch((error) => {

                    Swal.fire({
                        html: '<h3>' + translate("errorHeading") + '</h3><p>' + translate("errorConnectionMessage") + '</p>',
                        icon: "error",
                        buttonsStyling: false,
                        confirmButtonText: translate("confirm"),
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                    });
                });
        };

        const searchContracts = (params) => {
            collapse.hide();

            Object.assign(queryParams.value, params);

            refresh();
        };

        onMounted(() => {
            getContracts();

            const collapseElement = document.getElementById('kt_collapse_contract');

            collapse = new Collapse(collapseElement, {
                toggle: false
            });

            collapseElement?.addEventListener('shown.bs.collapse', function () {
                scrollTo("kt_collapse_contract");
            });

            setCurrentPageBreadcrumbs(
                translate("contractManagementHeading"), [
                    translate("masterDataHeading"),
                    translate("contractManagementHeading")
                ]
            );
        });

        return {
            translate,
            contracts,
            queryParams,
            queryParamsLabels,
            queryParamsDefault,
            removeQueryParam,
            searchContracts,
            pagination,
            activeContract,
            loading,
            refresh,
            goPage,
            create,
            edit,
            confirmRemove,
            setOffset,
        };
    },
});
