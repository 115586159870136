import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-lg-6 col-md-12 col-sm-12 pt-10 pb-10" }
const _hoisted_3 = { class: "form-check form-switch form-check-custom form-check-solid d-inline-block ms-2" }
const _hoisted_4 = ["checked"]
const _hoisted_5 = { class: "form-check-label fw-bold text-gray-400" }
const _hoisted_6 = { class: "col-lg-6 col-md-12 col-sm-12 pt-10 pb-10" }
const _hoisted_7 = { class: "form-check form-switch form-check-custom form-check-solid d-inline-block ms-2" }
const _hoisted_8 = ["checked"]
const _hoisted_9 = { class: "form-check-label fw-bold text-gray-400" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ContractPriceScheduleRent = _resolveComponent("ContractPriceScheduleRent")!
  const _component_ContractPriceScheduleMaintenance = _resolveComponent("ContractPriceScheduleMaintenance")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("label", _hoisted_3, [
          _createElementVNode("input", {
            type: "checkbox",
            value: "1",
            name: "is_location",
            class: "form-check-input",
            id: "isLocation",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.bool1 = !_ctx.bool1)),
            onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.reset('loc'))),
            checked: _ctx.bool1 == false
          }, null, 40, _hoisted_4),
          _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.translate("contractAspectLocationLabel")), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("label", _hoisted_7, [
          _createElementVNode("input", {
            type: "checkbox",
            value: "1",
            name: "is_maintenance",
            class: "form-check-input",
            id: "isMaint",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.bool2 = !_ctx.bool2)),
            onChange: _cache[3] || (_cache[3] = ($event: any) => (_ctx.reset('maint'))),
            checked: _ctx.bool2 == false
          }, null, 40, _hoisted_8),
          _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.translate("contractAspectMaintenanceLabel")), 1)
        ])
      ])
    ]),
    _createElementVNode("div", {
      class: _normalizeClass({'d-none': _ctx.bool1})
    }, [
      _createVNode(_component_ContractPriceScheduleRent)
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass({'d-none': _ctx.bool2})
    }, [
      _createVNode(_component_ContractPriceScheduleMaintenance)
    ], 2)
  ], 64))
}