
import { useI18n } from "vue-i18n/index";
import * as Yup from "yup";
import axios, { AxiosResponse } from "axios";
import moment from 'moment';
import { defineComponent, ref, onMounted } from "vue";
import { ErrorMessage, Field, Form, useForm } from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { hideModal } from "@/core/helpers/dom";
import ApiRoutes from "@/core/config/ApiRoutes";

interface FormInterface {
    id: any;
    vehicle_id: number;
    employee_id: number;
    structure_type: number;
    structure_code: string;
    driver_id: number;
    begins: string;
    ends: string;
}

export default defineComponent({
    name: "services-vehicle-control-edit-modal",
    emit: ["refreshData"],
    props: {
        vehicleAssignment: null
    },
    components: {
        ErrorMessage,
        Field,
        Form,
    },
    setup(props, { emit }) {
        const { t, te } = useI18n();

        let employees = ref([]);
        let drivers = ref([]);
        let structures = ref([]);
        let isUpdate = false as boolean;
        const submitButtonRef = ref<null | HTMLButtonElement>(null);
        const editVehicleAssignmentModalRef = ref<null | HTMLElement>(null);
        const vehicleAssignmentData = ref<any>({});
        const vehicleAssignmentDefaultData = {
            id: null,
            vehicle_id: 0,
            employee_id: 0,
            structure_type: 1,
            structure_code: "",
            driver_id: 0,
            begins: "",
            ends: "",
        };
        Object.assign(vehicleAssignmentData.value, vehicleAssignmentDefaultData);

        const dateFormat = (value: string, format: string = 'YYYY-MM-DD'): string => {
            return moment(value).format(format);
        };

        const translate = (text, args = null) => {
            if (te(text)) {
                return t(text, args);
            } else {
                return text;
            }
        };

        const schema = Yup.object().shape({
            employee_id: Yup.number().required().min(1).label(translate("vehicleAssignmentEmployeeIdLabel")),
            driver_id: Yup.number().required().min(1).label(translate("vehicleAssignmentDriverIdLabel")),
            begins: Yup.date().required().label(translate("vehicleAssignmentBeginsLabel")),
            ends: Yup.date().required().label(translate("vehicleAssignmentEndsLabel")),
        });

        const { resetForm, handleReset } = useForm<FormInterface>({
            initialValues: {
                id: null,
                vehicle_id: 0,
                employee_id: 0,
                structure_type: 1,
                structure_code: "",
                driver_id: 0,
                begins: "",
                ends: "",
            }
        });

        const getEmployees = async () => {
            const response = await axios.get(ApiRoutes.employees.list, { params: { structure_code: vehicleAssignmentData.value?.structure_code } });

            employees.value = response.data;
        };

        const getDrivers = async () => {
            const response = await axios.get(ApiRoutes.employees.list, { params: {
                id: vehicleAssignmentData.value?.id,
                begins: vehicleAssignmentData.value?.begins,
                ends: vehicleAssignmentData.value?.ends,
                is_driver: 1
            } });

            drivers.value = response.data;
        };

        const getStructures = async () => {
            const level = (vehicleAssignmentData.value) ? vehicleAssignmentData.value.structure_type : 1;
            const response = await axios.get(ApiRoutes.structures.list, { params: { level: level } });

            structures.value = response.data;
        };

        const submit = (values, { resetForm }) => {
            if (!submitButtonRef.value) {
                return;
            }

            // Disable button
            submitButtonRef.value.disabled = true;
            // Activate indicator
            submitButtonRef.value.setAttribute("data-kt-indicator", "on");

            if (isUpdate && props.vehicleAssignment.id) Object.assign(vehicleAssignmentData.value, {id: props.vehicleAssignment.id});

            axios.post(ApiRoutes.vehicleAssignments.updateOrCreate, vehicleAssignmentData.value)
                .then((response: AxiosResponse) => {

                    if (submitButtonRef.value) {
                        submitButtonRef.value.disabled = false;

                        submitButtonRef.value?.removeAttribute("data-kt-indicator");
                    }

                    if (response.data && response.data.success) {

                        Swal.fire({
                            text: response.data.message,
                            icon: "success",
                            buttonsStyling: false,
                            confirmButtonText: translate("confirm"),
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                        }).then(() => {
                            // actions.resetForm();

                            hideModal(editVehicleAssignmentModalRef.value);

                            emit("refreshData");
                        });

                    } else if (response.data && response.data.error) {

                        let error = (Array.isArray(response.data.error)) ? response.data.error.join('</li><li>') : response.data.error;

                        Swal.fire({
                            html: '<h3>' + translate("errorHeading") + '</h3><div class="alert alert-danger"><ul class="list-unstyled mb-0"><li>' + error + "</li></ul></div>",
                            icon: "error",
                            buttonsStyling: false,
                            confirmButtonText: translate("confirm"),
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                        });
                    }
                })
                .catch((error) => {

                    if (submitButtonRef.value) {
                        submitButtonRef.value.disabled = false;

                        submitButtonRef.value?.removeAttribute("data-kt-indicator");
                    }

                    Swal.fire({
                        html: '<h3>' + translate("errorHeading") + '</h3><p>' + translate("errorConnectionMessage") + '</p>',
                        icon: "error",
                        buttonsStyling: false,
                        confirmButtonText: translate("confirm"),
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                    });
                });
        };

        onMounted(() => {
            getDrivers();
            getStructures();

            const modal = document.getElementById('kt_modal_edit_contract_price_rent');

            if (modal) modal.addEventListener('hidden.bs.modal', function() {
                Object.assign(vehicleAssignmentData.value, vehicleAssignmentDefaultData);

                handleReset();
            });

            if (modal) modal.addEventListener('shown.bs.modal', async function() {
                isUpdate = false;

                if (props.vehicleAssignment && props.vehicleAssignment.hasOwnProperty('id')) {
                    isUpdate = true;

                    vehicleAssignmentData.value = {
                        id: props.vehicleAssignment.id,
                        vehicle_id: props.vehicleAssignment.vehicle_id,
                        employee_id: props.vehicleAssignment.employee_id,
                        driver_id: props.vehicleAssignment.driver_id,
                        structure_type: props.vehicleAssignment.employee.structure_type,
                        structure_code: props.vehicleAssignment.employee.structure_code,
                        begins: dateFormat(props.vehicleAssignment.begins),
                        ends: dateFormat(props.vehicleAssignment.ends),
                    };

                    getEmployees();
                }
            });
        });

        return {
            translate,
            dateFormat,
            employees,
            getEmployees,
            drivers,
            getDrivers,
            structures,
            getStructures,
            vehicleAssignmentData,
            schema,
            submit,
            submitButtonRef,
            editVehicleAssignmentModalRef,
        };
    },
});
