
import { useStore } from "vuex";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { useI18n } from "vue-i18n/index";
import * as Yup from "yup";
import moment from 'moment';
import axios, { AxiosResponse } from "axios";
import { defineComponent, ref, onMounted } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.js";
import ApiRoutes from "@/core/config/ApiRoutes";

import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

export default defineComponent({
    name: "services-fleet-vehicle-edit",
    emit: ["refreshData"],
    components: {
        ErrorMessage,
        Datepicker,
        Field,
        Form,
    },
    setup() {
        const { t, te } = useI18n();
        const store = useStore();

        let isUpdate = false as boolean;
        let supplier_types   = ref([]);
        let suppliers        = ref([]);
        let formData = new FormData();

        const submitButtonRef = ref<null | HTMLButtonElement>(null);
        // const editVehicleModalRef = ref<null | HTMLElement>(null);
        const contractDefaultData = {
            id                   :null,
            code                 :"",
            designation          :"",
            object               :"",
            path                 :"",
            date_begin           :"",
            date_end             :"",
            supplier_type_id     :0,
            supplier_id          :0,
            date_sign            :"",
            date_eff             :"",
            client_dom           :"",
            client_rep           :"",
            supplier_dom         :"",
            supplier_rep         :"",
            selectedFile         :File,
            note                 :"",
        }
        const contractData = ref<any>({});
        Object.assign(contractData.value, contractDefaultData);

        const translate = (text, args = null) => {
            if (te(text)) {
                return t(text, args);
            } else {
                return text;
            }
        };

        // const validationSchema = Yup.object().shape({
            // type_id: Yup.number().min(1).label(translate("vehicleTypeIdLabel")),
            // brand_id: Yup.number().min(1).label(translate("vehicleBrandIdLabel")),
            // model_id: Yup.number().min(1).label(translate("vehicleModelIdLabel")),
            // year: Yup.number().min((new Date().getFullYear()) - 80).max((new Date().getFullYear()) + 1).label(translate("vehicleYearLabel")),
            // external: Yup.boolean().label(translate("vehicleExternalLabel")),
            // color_id: Yup.number().min(1).label(translate("vehicleColorIdLabel")),
            // fuel_id: Yup.number().min(1).label(translate("vehicleFuelIdLabel")),
            // gearbox_id: Yup.number().min(1).label(translate("vehicleTypeIdLabel")),
            // vin: Yup.string().required().label(translate("vehicleVinLabel")),
            // epc: Yup.string().required().label(translate("vehicleEpcLabel")),
            // vrn: Yup.array().required().label(translate("vehicleVrnLabel")),
        // });

        const submit = (values, actions) => {
            if (!submitButtonRef.value) {
                return;
            }

            // Disable button
            submitButtonRef.value.disabled = true;
            // Activate indicator
            submitButtonRef.value.setAttribute("data-kt-indicator", "on");

            //  if (isUpdate){
            //      contractData.value.id = contract.id;
            //      formData.append('id', contractData.value.id);
            // }
            let config = { headers: { 'Content-Type': 'multipart/form-data' } }

            if (contractData.value.id) {
                formData.append('id', contractData.value.id);
            }else{
                formData.append('id','');
            }


            formData.append('code', contractData.value.code);
            formData.append('designation', contractData.value.designation);
            formData.append('object', contractData.value.object);
            formData.append('supplier_type_id', contractData.value.supplier_type_id);
            formData.append('supplier_id', contractData.value.supplier_id);
            formData.append('path', contractData.value.path);
            formData.append('date_begin', dateFormat(contractData.value.date_begin));
            formData.append('date_end', dateFormat(contractData.value.date_end));
            formData.append('date_sign', dateFormat(contractData.value.date_sign));
            formData.append('date_eff', dateFormat(contractData.value.date_eff));
            formData.append('client_dom', contractData.value.client_dom);
            formData.append('client_rep', contractData.value.client_rep);
            formData.append('supplier_dom', contractData.value.supplier_dom);
            formData.append('supplier_rep', contractData.value.supplier_rep);
            formData.append('note', contractData.value.note);

            axios.post(ApiRoutes.contracts.updateOrCreate,formData, config)
                .then((response: AxiosResponse) => {


                    if (submitButtonRef.value) {
                        submitButtonRef.value.disabled = false;

                        submitButtonRef.value?.removeAttribute("data-kt-indicator");
                    }

                    if (response.data && response.data.success) {
                         formData = new FormData();

                        Swal.fire({
                            text: response.data.message,
                            icon: "success",
                            buttonsStyling: false,
                            confirmButtonText: "Confirmer",
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                        }).then(() => {
                            // actions.resetForm();

                            store.dispatch(Actions.REFRESH_VEHICLES, (isUpdate) ? 2 : 1);
                        });

                    } else if (response.data && response.data.error) {

                        let error = (Array.isArray(response.data.error)) ? response.data.error.join('</li><li>') : response.data.error;

                        Swal.fire({
                            html: '<h3>' + translate("errorHeading") + '</h3><div class="alert alert-danger"><ul class="list-unstyled mb-0"><li>' + error + "</li></ul></div>",
                            icon: "error",
                            buttonsStyling: false,
                            confirmButtonText: translate("confirm"),
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                        });
                    }
                })
                .catch((error) => {

                    if (submitButtonRef.value) {
                        submitButtonRef.value.disabled = false;

                        submitButtonRef.value?.removeAttribute("data-kt-indicator");
                    }

                    Swal.fire({
                        html: '<h3>' + translate("errorHeading") + '</h3><p>' + translate("errorConnectionMessage") + '</p>',
                        icon: "error",
                        buttonsStyling: false,
                        confirmButtonText: translate("confirm"),
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                    });
                });
        };

        const getSupplierTypes = async () => {
            const response = await axios.get(ApiRoutes.suppliers.types);

            supplier_types.value = response.data;
        };

        const getSuppliers = async () => {
            const response = await axios.get(ApiRoutes.suppliers.list);

            suppliers.value = response.data;
        };

        // file upload config function
        const onFileChanged =  async (event) => {
             console.log(event.target.files[0]);

             if( (event.target.files[0].type != "application/pdf") &&
                 (event.target.files[0].type != "image/png" ) &&
                 (event.target.files[0].type != "image/jpg" ) &&
                 (event.target.files[0].type != "image/jpeg" ) &&
                  (event.target.files[0].size >1000000))

             {
                 Swal.fire({
                            text: "le fichier ne peux pas depasser 1 MB, et vous pouvez charger seulement fichier du type PDF ou IMAGE",
                            icon: "warning",
                            buttonsStyling: false,
                            confirmButtonText: translate("confirm"),
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                        });
             }else {
                    contractDefaultData.selectedFile=event.target.files[0];
                    formData.append('file',event.target.files[0]);
                    // const element: HTMLElement = document.getElementById('fileName') as HTMLElement
                    // element.innerHTML = event.target.files[0].name;
             }

        };

        const dateFormat = (value) => {
            return moment(value).format('YYYY-MM-DD');
        };




        store.watch(
            (state, getters) => getters.getVehicle,
            (contract) => {

                if (contract) {
                    isUpdate = true;
                    // formData.append('id', contract.id);

                    Object.keys(contract).forEach((key) => {
                        if (contractDefaultData.hasOwnProperty(key)) contractData.value[key] = contract[key];
                    });

                } else {
                    isUpdate = false;

                    Object.assign(contractData.value, contractDefaultData);
                    // formData.append('id',contractData.value.id);
                    // contractData.value.id = null;
                }
            });

        onMounted(() => {
            // formData = new FormData();
            // const element: HTMLElement = document.getElementById('contractFile') as HTMLElement
            //    element.innerHTML = translate('search') + '...';
            //    document!.getElementById("contractFile")!.innerHTML = "54442875*9*";
            //    (<HTMLInputElement>document.getElementById('contractFile')).value = "asdfghjk";
            getSupplierTypes();
            getSuppliers();

        });

        return {
            translate,
            supplier_types,
            suppliers,
            contractData,
            onFileChanged,
            // validationSchema,
            submit,
            submitButtonRef,
            // editVehicleModalRef,
        };
    },
});
