
import { useStore } from "vuex";
import { useI18n } from "vue-i18n/index";
import { defineComponent, ref, onMounted } from "vue";
import { Collapse, Tab } from "bootstrap";

import ContractEdit from "@/views/contracts/ContractEdit.vue";
import VehicleInsurances from "@/views/services/fleet/VehicleInsurances.vue";
import ContractAspect from "@/views/contracts/ContractAspect.vue";
import VehicleAssignments from "@/views/services/fleet/VehicleAssignments.vue";
import ContractPriceSchedule from "@/views/contracts/ContractPriceSchedule.vue";
import VehicleMissions from "@/views/services/fleet/VehicleMissions.vue";

export default defineComponent({
    name: "services-fleet-vehicle",
    components: {
        ContractEdit,
        VehicleInsurances,
        ContractAspect,
        VehicleAssignments,
        ContractPriceSchedule,
        VehicleMissions,
    },
    setup() {
        const { t, te } = useI18n();
        const store = useStore();

        const contract = ref<any>({});

        const translate = (text, args = null) => {
            if (te(text)) {
                return t(text, args);
            } else {
                return text;
            }
        };
        const scrollTo = (element: string) => {

            document.getElementById(element)?.scrollIntoView({
                behavior: 'smooth'
            });
        };


        // onMounted(() => {
        //     const collapseE = document.getElementById('kt_collapse_contract789');

        //     let colk = new Collapse(collapseE, {
        //         toggle: false
        //     });

        //     collapseE?.addEventListener('shown.bs.collapse', function () {
        //         scrollTo("kt_collapse_contract789");
        //     });

        //     colk.show();




        // });



        store.watch(
            (state, getters) => getters.getVehicle,
            (activeContract) => {
                contract.value = {
                    id: 0,
                };

                if (activeContract) {
                    Object.assign(contract.value, { id: activeContract.id });
                }
            });

        return {
            contract,
            translate,
        };
    },
});
