
import { useStore } from "vuex";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { useI18n } from "vue-i18n/index";

import axios, { AxiosResponse } from "axios";
import { defineComponent, ref, onMounted } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.js";
import ApiRoutes from "@/core/config/ApiRoutes";



export default defineComponent({
    name: "views-contracts-ContractAspect",
    emit: ["refreshData"],
    components: {
        ErrorMessage,
        Field,
        Form,
    },
    setup() {
        const { t, te } = useI18n();
        const store = useStore();

        let isUpdate = false as boolean;
        let supplier_types   = ref([]);
        let suppliers        = ref([]);

        let bool1             =true;

        let bool2             =true;


        const submitButtonRef = ref<null | HTMLButtonElement>(null);

        const contractAspectDefault = {
            id                   :null,
            is_location          :'0',
            is_maintenance       :'0',
            total_amount         :null,
            location_amount      :null,
            maintenance_amount   :null,
        }
        const contractAspectData = ref<any>({});
        Object.assign(contractAspectData.value, contractAspectDefault);

        const translate = (text, args = null) => {
            if (te(text)) {
                return t(text, args);
            } else {
                return text;
            }
        };


        const reset = (btn) => {

            (btn == 'loc') ? contractAspectData.value.location_amount = "" : contractAspectData.value.maintenance_amount = "";

        };



        const submit = (values, actions) => {
            if (!submitButtonRef.value) {
                return;
            }

            // Disable button
            // submitButtonRef.value.disabled = true;
            // Activate indicator
            // submitButtonRef.value.setAttribute("data-kt-indicator", "on");

            //  if (isUpdate){
            //      contractData.value.id = contract.id;
            //      formData.append('id', contractData.value.id);
            // }
            let config = { headers: { 'Content-Type': 'multipart/form-data' } }


            axios.post(ApiRoutes.contracts.updateOrCreate,contractAspectData)
                .then((response: AxiosResponse) => {

                    if (submitButtonRef.value) {
                        submitButtonRef.value.disabled = false;

                        submitButtonRef.value?.removeAttribute("data-kt-indicator");
                    }

                    if (response.data && response.data.success) {

                        Swal.fire({
                            text: response.data.message,
                            icon: "success",
                            buttonsStyling: false,
                            confirmButtonText: "Confirmer",
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                        }).then(() => {
                            // actions.resetForm();

                            store.dispatch(Actions.REFRESH_VEHICLES, (isUpdate) ? 2 : 1);
                        });

                    } else if (response.data && response.data.error) {

                        let error = (Array.isArray(response.data.error)) ? response.data.error.join('</li><li>') : response.data.error;

                        Swal.fire({
                            html: '<h3>' + translate("errorHeading") + '</h3><div class="alert alert-danger"><ul class="list-unstyled mb-0"><li>' + error + "</li></ul></div>",
                            icon: "error",
                            buttonsStyling: false,
                            confirmButtonText: translate("confirm"),
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                        });
                    }
                })
                .catch((error) => {

                    if (submitButtonRef.value) {
                        submitButtonRef.value.disabled = false;

                        submitButtonRef.value?.removeAttribute("data-kt-indicator");
                    }

                    Swal.fire({
                        html: '<h3>' + translate("errorHeading") + '</h3><p>' + translate("errorConnectionMessage") + '</p>',
                        icon: "error",
                        buttonsStyling: false,
                        confirmButtonText: translate("confirm"),
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                    });
                });
        };

        store.watch(
            (state, getters) => getters.getVehicle,
            (contract) => {

                if (contract) {
                    isUpdate = true;

                    Object.keys(contract).forEach((key) => {
                        if (contractAspectDefault.hasOwnProperty(key)) contractAspectData.value[key] = contract[key];
                    });

                } else {
                    isUpdate = false;

                    Object.assign(contractAspectData.value, contractAspectDefault);

                }
            });

        onMounted(() => {


        });

        return {
            translate,
            supplier_types,
            suppliers,
            contractAspectData,

            submit,
            reset,
            submitButtonRef,

        };
    },
});
